<template>
  <div>
    <div class="marcas-main-bg scotsman-main-bg">
      <div class="w-full h-full block">
        <div class="container h-full">
          <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h2 class="main-text">Scotsman</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-12">
      <div class="container text-center">
        <h2>
            Cuando se trata de lujo, los detalles marcan la diferencia.
            Tu bebida favorita no es la excepción. La adición de un detalle, el hielo ideal,
            hace que una bebida se mantenga más fría y el sabor dure y dure.
            No importa qué bebida prefiera, una cosa puede mejorarla:
            el hielo Scotsman, el ingrediente ideal.
        </h2>
      </div>
    </div>
    <brandsSeries brand="Scotsman" />
  </div>
</template>
<script>
import brandsSeries from './../BrandsSeries.vue'

export default {
  components:{
    brandsSeries
  },
  methods: {
    handlerCollapse: function (subcategory) {
      if(subcategory == this.subcategory){
        this.subcategory = null;
        this.collapseOpen = false;
      }else{
        this.subcategory = subcategory;
        this.collapseOpen = true;
      }
      
    },
  },
  mounted(){
    window.scrollTo(0, 0)
  }
};
</script>