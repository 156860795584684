<template>
  <div>
    <div :class="`lg:flex items-center justify-between w-full bg-gray-100 lg:px-12 py-12 mb-12 ${i%2 == 1 ? 'flex-row-reverse' : ''}`" v-for="(serie, i) in series" :key="i">
      <div class="w-full items-center lg:w-1/2 text-center lg:text-left px-12">
        <h2 class="font-bold"> {{serie.name}} </h2>
        <p v-html="serie.text"></p>
      </div>
      <div class="w-full flex justify-center lg:w-1/2 px-12">
        <img
          class="w-3/4"
          :src="serie.image"
          alt=""
        />
      </div>
    </div>

    <div class="mt-12">
      <div class="text-center">
        <div class="container flex justify-center mb-3">
          <div class="hr-custom text-right">
            <span class="hr-inner"></span>
          </div>
          <h2 class="w-full">Nuestros Productos</h2>
          <div class="hr-custom text-left">
            <span class="hr-inner"></span>
          </div>
        </div>

        <div
          class=""
        >
          <div
            class="mt-12 flex flex-col text-center items-center"
          >
            <h2>
              Para ver la línea completa de productos
              visite nuestro catalogo.
            </h2>
            <div class="mt-6 justify-center">
                <router-link :to="'/catalogo?brand='+brand">
                    <t-button>VER PRODUCTOS</t-button>
                </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="my-12">
      <div class="text-center">
        <div class="container flex justify-center">
          <div class="hr-custom text-right">
            <span class="hr-inner"></span>
          </div>
          <h2 class="w-full">ESTAMOS PARA ATENDERLE</h2>
          <div class="hr-custom text-left">
            <span class="hr-inner"></span>
          </div>
        </div>
        <h2>
          Ya sea que necesite ayuda para escoger sus electrodomésticos <br />
          o solamente desee comprarlos. Nuestros expertos están para ayudarle.
        </h2>
        <div class="mt-12 flex justify-center">
          <t-button>LLAMENOS</t-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { series } from "./utils/brandsSeries";

export default {
    name:"BrandsSeries",
    data(){
        return {
            series: []
        }
    },
    props:{
        brand: String
    },
    mounted(){
        this.series = series.filter((serie) => serie.brand == this.brand)
    }
};
</script>
