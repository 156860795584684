<template>
    <b-navbar fixed-top type="is-black" spaced>
        <template #brand>
            <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/' }">
                <img
                    src="../assets/images/logo.png"
                    alt="Lightweight UI components for Vue.js based on Bulma"
                >
            </b-navbar-item>
        </template>
        <template #end>
            <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/' }">
                Inicio
            </b-navbar-item>
             <b-navbar-dropdown class="text-1xl" label="Nuestras Marcas">
                <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/sub-zero' }">
                    Sub Zero
                </b-navbar-item>
                <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/wolf' }">
                    Wolf
                </b-navbar-item>
                <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/wolf-gourmet' }">
                    Wolf Gourmet
                </b-navbar-item>
                <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/cove' }">
                    Cove
                </b-navbar-item>
                <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/asko' }">
                    Asko
                </b-navbar-item>
                <!-- <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/franke' }">
                    Franke
                </b-navbar-item> -->
                <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/scotsman' }">
                    Scotsman
                </b-navbar-item>
                <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/plum' }">
                    Plum
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/catalogo' }">
                Catalogo
            </b-navbar-item>
            <b-navbar-item class="text-1xl" tag="router-link" :to="{ path: '/contacto' }">
                Contacto
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
export default {
    name:'NavBar'
}
</script>
