<template>
  <div id="app">
    <NavBar/>
    <div>
      <router-view></router-view>
    </div>
    <Footer/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'mainApp',
  data(){
    return{
      brandFilter:"",
    }
  },
  components: {
    NavBar,
    Footer
  }
}
</script>

<style>
#app {
  font-family: 'helveticaneue-thin', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica-Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

.welcome-bg {
  background-image: url(./assets/images/fondos/mainpic.jpg);
}

.contacto-bg {
  background-image: url(./assets/images/fondos/welcome-bg2.jpg);
}

.wolf-main-bg {
  background-image: url(./assets/images/fondos/wolf-bg.png);
}

.wolf-gourmet-main-bg {
  background-image: url(./assets/images/fondos/wolf-gourmet-bg.jpg);
}

.asko-main-bg {
  background-image: url(./assets/images/fondos/asko-bg.png);
}

.cove-main-bg {
  background-image: url(./assets/images/fondos/cove-bg.png);
}

.franke-main-bg {
  background-image: url(./assets/images/fondos/franke-bg.png);
}

.plum-main-bg {
  background-image: url(./assets/images/fondos/plum-bg.png);
}

.scotsman-main-bg {
  background-image: url(./assets/images/fondos/scotsman-bg.png);
}

.sub-zero-main-bg {
  background-image: url(./assets/images/fondos/sub-zero-bg.png);
}

.catalog-main-bg {
  background-image: url(./assets/images/fondos/catalog.jpg);
}

.opacus-bg {
  color: #FFFFFF !important;
  background-color: rgba(0,0,0,0.4);
  z-index: 0;
}

.marcas-main-bg {
  background-position: center center;
  visibility: visible;
  opacity: 1;
  transition: none 0s ease 0s;
  transform: translateZ(0px);
  width: 100%;
  height: 100vh;
  background-size: cover;
  min-height: 250px;
  position: relative;
}

h2 {
  font-size: 20px !important;
  font-weight: 400 ;
}

p {
  font-size: 15px !important;
  font-weight: 400 ;
}

.caption_fullwidth {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 10;
}
.slideshow_align_caption {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}
.main-text {
  font-size: 50px !important;
  font-weight: 400 !important;
  color: white;
}
.secondary-text {
  font-size: 30px !important;
  font-weight: 400 !important;
  color: white;
}
.slideshow_inner_caption {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
}
.slideshow_caption {
  left: 0;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  top: auto;
  height: 100%;
  display: block;
  text-decoration: none;
}
.hr-custom {
  margin: 0;
  display: block;
  clear: both;
  min-height: 21px;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  float: left;
  line-height: 25px;
  position: relative;
  border-color: #ebebeb;
}
.hr-inner {
  width: 100px;
  border-color: #39393f;
  border-top-width: 2px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
</style>
