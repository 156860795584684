<template>
  <div>
    <div class="marcas-main-bg welcome-bg">
      <div class="w-full h-full block">
        <div class="container h-full">
          <div class="slideshow_caption"></div>
        </div>
      </div>
    </div>

    <div class="text-center font-bold mt-12">NUESTRAS MARCAS</div>
    <div class="grid grid-cols-1 md:grid-cols-2">
        <div
            v-for="(marca, i) in marcas"
            :key="i"
            class="justify-items-center mt-12"
        >
        <router-link :to="{ path: `/${marca.marca}` }">
            <div class="flex justify-center items-center my-6">
            <img :class="(i%2 != 0) ? 'w-3/5' : ''" :src="(i%2 == 0) ? marca.url : marca.ambient" alt="" />
            <img :class="(i%2 == 0) ? 'w-3/5' : ''" :src="(i%2 != 0) ? marca.url : marca.ambient" alt="" />
        </div>
        </router-link>
        </div>
    </div>

    <div class="newsletter-bg">
      <div class="relative w-full text-white">
        <div class="opacus-overlay-bg">
          <div class="container text-center py-24">
            <h2 class="text-5xl">INSCRIBASE A NUESTRO NEWSLETTER</h2>
            <h4>
              Para promociones, nuevos productos y ofertas. Directamente a su
              bandeja de entrada
            </h4>
            <div class="mt-12 flex justify-around">
              <t-input placeholder="Nombre" name="my-input2" />
              <t-input placeholder="Email" name="my-input1" />
            </div>
            <label class="flex justify-center mt-2">
              <t-checkbox name="options" value="a" />
              <span class="ml-2 text-sm"
                >Doy consentimiento de utilizar mis datos personales</span
              >
            </label>

            <div class="mt-12 flex justify-center">
              <t-button>INSCRIBIRSE</t-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  data() {
    return {
      marcas: [
        {
          marca: "sub-zero",
          url: "img/logos/logo-sub-zero.png",
          ambient: "img/sub-zer-landing.png",
          alt: "suzero-wolf-cove-content-1",
        },
        {
          marca: "wolf",
          url: "img/logos/logo-wolf.png",
          ambient: "img/wolf-2.jpg",
          alt: "suzero-wolf-cove-content-1",
        },
        {
          marca: "wolf-gourmet",
          url: "img/logos/logo-wolf-gourmet.jpg",
          ambient: "img/main-pic-gourmet.jpg",
          alt: "suzero-wolf-cove-content-1",
        },
        {
          marca: "cove",
          url: "img/logos/logo-cove.png",
          ambient: "img/cotice.jpg",
          alt: "suzero-wolf-cove-content-1",
        },
        {
          marca: "asko",
          url: "img/logos/logo-asko.png",
          ambient: "img/ASKO-1.png",
          alt: "asko-content-1",
        },
/*         {
          marca: "franke",
          url: "img/logos/logo-franke.png",
          ambient: "img/FRANKE-1.png",
          alt: "FRANKE-content-1",
        }, */
        {
          marca: "scotsman",
          url: "img/logos/logo-scotsman.png",
          ambient: "img/SCOTSMAN-1.png",
          alt: "SCOTSMAN-content-1",
        },
        {
          marca: "plum",
          url: "img/logos/logo-plum.png",
          ambient: "img/PLUM-1.png",
          alt: "PLUM-content-1",
        },
      ],
    };
  },
  mounted(){
    window.scrollTo(0, 0)
  }
};
</script>

<style scoped>
.newsletter-bg {
  background-repeat: no-repeat;
  background-image: url("./../assets/images/newsletter.png");
  background-attachment: scroll;
  background-position: top center;
}
.opacus-overlay-bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
</style>