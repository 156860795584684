<template>
  <div>
    <div class="marcas-main-bg franke-main-bg">
      <div class="w-full h-full block">
        <div class="container h-full">
          <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h2 class="main-text">Franke</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-12">
      <div class="container text-center">
        <h2>
            Franke le ofrece una oportunidad única para transformar su cocina,
            en un lugar para vivir de acuerdo a sus expetativas.
            Su amplia variedad de estilos en tarjas y llaves satisfacerá todas sus necesidades,
            al final sólo es cuestión de elección: SU ELECCIÓN.
        </h2>
      </div>
    </div>
    <div>
      <brandsSeries brand="franke" />
    </div>
  </div>
</template>
<script>
import brandsSeries from './../BrandsSeries.vue'
export default {
    components:{
    brandsSeries
  },
  methods: {
    handlerCollapse: function (subcategory) {
      if(subcategory == this.subcategory){
        this.subcategory = null;
        this.collapseOpen = false;
      }else{
        this.subcategory = subcategory;
        this.collapseOpen = true;
      }
      
    },
  },
  mounted(){
    window.scrollTo(0, 0)
  }
};
</script>