<template>
  <div class="py-12 bg-black">
    <div class="flex justify-between container text-white">
      <div class="md:ml-5">
        <img class="mb-3" src="img/logotemporal.png" alt="" />
        <h4 class="mb-3">+52 811 181 1478</h4>
        <h4>info@morosini.mx</h4>
      </div>
      <div class="md:mr-5">
        <h4 class="mb-3 font-bold">SIGUENOS EN:</h4>
        <div>
          <h4 class="mb-3">
            Facebook:
            <a
              class="font-bold"
              target="_blank"
              href="https://www.facebook.com/Grupo-Morosini-374285289802604"
              >Grupo Morosini</a
            >
          </h4>
          <h4>
            Instagram:
            <a
              class="font-bold"
              target="_blank"
              href="https://www.instagram.com/grupomorosini/"
              >grupomorosini</a
            >
          </h4>
        </div>
        <div class="flex justify-center mt-4">
          <div class="cursor-pointer">
            <h4 @click="login = true">
              <span class="font-bold">INICIAR SESION</span>
            </h4>
          </div>

          <t-modal v-model="login">
            <template v-slot:header>
                 <h2 class="text-black font-bold">Iniciar sesión</h2>
            </template>

            <div class="mx-12">
                <t-input placeholder="Contraseña" name="my-password" v-model="password"/>
            </div>

            <div v-show="errorMessage">
                <t-alert class="mt-3" variant="error" :show={errorMessage} dismissible="false">
                    Contraseña incorrecta
                </t-alert>
            </div>

            <template v-slot:footer>
              <div class="flex justify-around">
                <t-button @click="handleLogIn()"> Confirmar </t-button>
              </div>
            </template>
          </t-modal>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      login: false,
      errorMessage:false,
    };
  },
  methods:{
      handleLogIn(){
          this.errorMessage = false
          const pass = this.password
          //console.log(pass.length === 13 && (pass.substring(0, pass.length - 4) === "COCINEROS") && (+pass.substring(pass.length - 4)*4 === 8088))
           if(pass.length === 13 && (pass.substring(0, pass.length - 4) === "COCINEROS") && (+pass.substring(pass.length - 4)*4 === 8088) ){
               window.location.href = "https://grupomorosini.odoo.com/shop/category/electrodomesticos-1"
           }else{
               this.errorMessage = true
               //setTimeout(()=>{this.errorMessage = false}, 1000)
           }
      }
  }
};
</script>
