<template>
  <div>
    <div class="marcas-main-bg wolf-gourmet-main-bg">
      <div class="w-full h-full opacus-bg block">
        <div class="container h-full">
          <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h2 class="main-text">Wolf Gourmet</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-12">
      <div class="container text-center">
        <h2>
            los electrodomésticos de encimera Wolf Gourmet se crean con la atencion al detalle que espera Wolf
            Deleitese con cada paso del proceso utilizando nuestros electrodomésticos de alto
            rendimiento diseñados para soportar los rigores de cualquier cocina
        </h2>
      </div>
    </div>
    <brandsSeries brand="wolf-gourmet" />
  </div>
</template>
<script>
import brandsSeries from './../BrandsSeries.vue'
export default {
  components:{
    brandsSeries
  },
  data() {
    return {
      collapseOpen: false,
      subcategory: null,
    };
  },
  methods: {
    handlerCollapse: function (subcategory) {
      if(subcategory == this.subcategory){
        this.subcategory = null;
        this.collapseOpen = false;
      }else{
        this.subcategory = subcategory;
        this.collapseOpen = true;
      }
      
    },
  },
  mounted(){
    window.scrollTo(0, 0)
  }
};
</script>