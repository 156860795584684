<template>
  <div>
    <div class="marcas-main-bg wolf-main-bg">
      <div class="w-full h-full block">
        <div class="container h-full">
          <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h2 class="main-text">Wolf</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-12">
      <div class="container text-center">
        <h2>
          Amigos reunidos alrededor de una mesa. Recetas familiares, servidas
          con amor. Y platillos, convertidos en una obra maestra. Desde
          crujientes panes artesanales hasta deliciosos filetes a la parrilla,
          Wolf equipa a los cocineros con la confianza para crear las comidas de
          las que están hechos los recuerdos más deliciosos.
        </h2>
      </div>
    </div>
    <div>
    <brandsSeries brand="wolf" />

<!--       <div class="grid grids-cols-1 md:grid-cols-3 gap-2">
        <div
          @click="handlerCollapse('estufas')"
          class="flex flex-col text-center justify-items-center items-center"
        >
          <h2>ESTUFAS</h2>
          <img src="img/subcategories/estufa_wolf.png" alt="" />
        </div>
        <div
          @click="handlerCollapse('hornos')"
          class="flex flex-col text-center justify-items-center items-center"
        >
          <h2>HORNOS EMPOTRADOS</h2>
          <img src="img/subcategories/hornos_wolf.png" alt="" />
        </div>
        <div
          @click="handlerCollapse('parrillas')"
          class="flex flex-col text-center justify-items-center items-center"
        >
          <h2>PARRILLAS</h2>
          <img src="img/subcategories/parrilla_wolf.png" alt="" />
        </div>
      </div>
      <div>
        <b-collapse :open="collapseOpen">
          <div v-if="!!collapseOpen" class="notification">
            <div class="content">
              <div
                class="
                  grid
                  grids-cols-1
                  lg:grid-cols-2
                  justify-items-center
                  mb-6
                "
              >
                <div class="text-center lg:text-right lg:w-2/5 w-full">
                  <h3>{{ subcategories[subcategory].title }}</h3>
                </div>
                <p class="text-center lg:text-left lg:w-3/5 w-full">
                  {{ subcategories[subcategory].description }}
                </p>
              </div>
              <div>
                <div
                  class="grid grid-cols-1 lg:grid-cols-3 justify-items-center"
                >
                  <div
                    v-for="(feat, i) in subcategories[subcategory].features"
                    :key="i"
                    class="
                      flex flex-col
                      text-center
                      justify-items-center
                      items-center
                      mt-6
                      lg:mt-0
                    "
                  >
                    <h4>{{ feat.type }}</h4>
                    <p class="px-4">
                      {{ feat.description }}
                    </p>
                    <img :src="feat.image" :alt="feat.type" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div> -->
    </div>
  </div>
</template>
<script>
import brandsSeries from './../BrandsSeries.vue'
export default {
  components:{
    brandsSeries
  },
  data() {
    return {
      collapseOpen: false,
      subcategory: null,
      subcategories: {
        estufas: {
          title: "ESTUFAS",
          description: `Disfrute de un control preciso
            gracias a décadas de destreza
            de ingenieríacon las gamas de
            Wolf. Los chefs caseros
            disfrutan del poder y la
            delicadeza de los
            electrodomésticos de cocina
            profesionales sabiendo que
            siempre pueden crear los
            platillos que tienen en mente
            con Wolf.`,
          features: [
            {
              type: "ESTUFAS DE GAS",
              description: `La precisión a altas y bajas
                    temperaturas se garantiza
                    con quemadores sellados
                    dobles apilados en la parte
                    superior y la potencia de
                    un horno de gas debajo.
                    Disponible en anchos de
                    30″ y 36″, o hasta 60″ con
                    dos hornos.`,
              image: "img/subcategories/estufas-de-gas.png",
            },
            {
              type: "ESTUFAS DUALES",
              description: `Une una estufa de gas con la
                    delicadeza del calor del
                    quemador selaldo dobles
                    apilados y el rendimmiento
                    uniforme de coccion de un
                    horno electrico de doble
                    conveccion. Disponible en
                    anchos de 30″ y 36″, o
                    hasta 60″ con dos hornos.`,
              image: "img/subcategories/estufas-duales.png",
            },
            {
              type: "ESTUFAS DE INDUCCIÓN",
              description: `El sistema de suministro de calor
                mas eficiente de Wolf disponible,
                induccion. Esta elegante y
                moderna gama proporciona
                velocidad, control y consistencia,
                encima de un horno eléctrico de
                doble convección. Disponible en
                anchos de 30″ y 36″.`,
              image: "img/subcategories/estufas-de-induccion.png",
            },
          ],
        },
        hornos: {
          title: "HORNOS EMPOTRADOS",
          description: `Hornee, ase y rostice con la confianza
            de los hornos empotrables Wolf.
            Los hornos de convección Wolf, o los
            hornos combinados con convección y
            vapor, ofrecen controles intuitivos y
            temperaturas constantes para
            garantizar que cada comida sea
            previsiblemente notable.`,
          features: [
            {
              type: "HORNOS DE CONVECCION",
              description: `Con los hornos de convección Wolf,
                    los ventiladores dobles hacen circular
                    el aire caliente trabajando en conjunto
                    con los elementos de calentamiento para 
                    garantizar una cocción uniforme y, por
                    lo tanto, resultados más consistentemente
                    deliciosos.`,
              image: "img/subcategories/hornos-de-conveccion.png",
            },
            {
              type: "HORNOS DE CONVECCION Y VAPOR",
              description: `Cocinar el vapor tiene innumerables
                    beneficios. diseñados con un sensor
                    de clima, los hornos de vapor y 
                    convección Wolf garantizan resultados
                    sabrosos que siempre están libres de
                    conjeturas.`,
              image: "img/subcategories/hornos-de-conveccion-y-vapor.png",
            },
            {
              type: "SPEED OVEN",
              description: `El rendimiento que espera de los
                    hornos Wolf, solo que más rápido y
                    más pequeño. Combina el poder del
                    microondas con las capacidades de
                    convección y asado en un dispositivo
                    fácil de usar. Ofrece versatilidad
                    de horno todo en uno, preparando
                    una amplia variedad de alimentos en
                    un tiempo reducido.`,
              image: "img/subcategories/speed-over.png",
            },
          ],
        },
        parrillas: {
          title: "PARRILLAS",
          description: `Independientemente del método de cocción 
            que prefiera(gas, electricidad o inducción),
            hay una parrilla Wolf elegante, aerodinámica
            y bien diseñada para usted.Tenga la seguridad
            de que cada una ofrece el control preciso de
            temperatura y el rendimiento comprobado que
            espera de Wolf.`,
          features: [
            {
              type: "PARRILLAS DE GAS",
              description: `Los quemadores de gas dobles
                    apilados y sellados de Wolf
                    proporcionan un calor preciso
                    de alto Btu para que herbores
                    y sellados rápidos, y llamas
                    bajas para cocinar a fuego
                    lento delicadas salsas.
                    Disponible en anchos de
                    15″, 24″, 30″ y 36″.`,
              image: "img/subcategories/parrillas-de-gas.png",
            },
            /* {
              type: "PARRILLAS DE GAS PROFESIONALES",
              description: `Los quemadores de gas dobles
                    apilados y sellados de Wolf
                    proporcionan un calor preciso
                    de alto Btu para que herbores
                    y sellados rápidos, y llamas
                    bajas para cocinar a fuego
                    lento delicadas salsas.
                    Disponible en anchos de
                    15″ 24″, 30″ y 36″.`,
              image: "img/subcategories/aaa.png",
            }, */
            {
              type: "PARRILLAS ELECTRICAS",
              description: `Facíl y convenientemente las parrillas
                    elécticas Wolf ofrecen ajustes de
                    temperatura sin interrupciones, 
                    incluidas características adicionales bien
                    pensadas, como una configuración
                    de derretir, Disponible en anchos de
                    15″, 30″ y 36″.`,
              image: "img/subcategories/parrillas-electricas.png",
            },
            {
              type: "PARRILLAS DE INDUCCION",
              description: `El sistema de suministro de calor más
                    eficiente de Wolf disponible, la
                    inducción, genera un campo
                    magnético que induce calor
                    directamente en los utensilios de
                    cocina con un control de
                    tempreratura preciso, Disponible en
                    anchos de 15″, 24″, 30″, 36″.`,
              image: "img/subcategories/parrillas-de-induccion.png",
            },
          ],
        },
      },
    };
  },
  methods: {
    handlerCollapse: function (subcategory) {
      if (subcategory == this.subcategory) {
        this.subcategory = null;
        this.collapseOpen = false;
      } else {
        this.subcategory = subcategory;
        this.collapseOpen = true;
      }
    },
  },
};
</script>

