export const series = [
    {
        name:"Serie Clasica",
        text:`
          El sistema de tamano completo de preservación de comida Sub Zero 
          es más que solo un refrigerador. Es un monumento a la comida
          fresca y deliciosa. Con una amplia selección de diseños, anchos y
          estilos personalizables de tamano completo, las unidades Sub-Zero
          estan disenadas para satisfacer las necesidades de cualquier espacio
          V mejorar cualquier decoración
        `,
        image: "img/brand-service/subzero-serie-clasica.jpg",
        productImages: [],
        brand:"subzero"
    },
    {
        name:"Refrigeradores PRO",
        text:`
            Nuestra línea de refrigeradores PRO es nuestro monumento a la
            conservacion de alimentos, esculpido completamente en acero
            inoxidable. Congelador en la parte izquierda, refrigerador en la parte
            derecha: el único con 3 evaporadores v2 compresores Disponible con
            o sin puerta de vidrio - resistente a luz ultravioleta - puede colocarlo
            en libre instalación o empotrado en su cocina.
        `,
        image: "img/brand-service/subzero-regriferadores-pro.jpg",
        productImages: [],
        brand:"subzero"
    },
    {
        name:"Serie Diseñador",
        text:`
            La innovación de la refrigeración diseñador de Sub-Zero permite que
            literalmente desaparezca en el ambiente done se instale, usando
            paneles vialaderas iguales a los gabinetes del resto de la cocina o si
            lo prefiere, con paneles opcionales de acero inoxidable con jaladera
            tubular o profesional que coordinaran con sus equipos wolf
        `,
        image: "img/brand-service/subzero-serie-disenador.jpg",
        productImages: [],
        brand:"subzero"
    },
    {
        name:"Refrigeracion bajo cubierta",
        text:`
            Agreque comodidad y conveniencia a su suite principal. Cree un
            impresionante bar de cocteles, con hielo incluido, en el estudio.
            Integre a la perfeccion un lugar para cosmeticos delicados o
            completa tu cocina al aire libre con bebidas frías y alimentos frescos
            listos para servir junto a la piscina. La refrigeracion bajo cubierta Sub
            Zero se integra a la perfección donde más se necesita
        `,
        image: "img/brand-service/subzero-refrigeracion-bajo-cubierta.jpg",
        productImages: [],
        brand:"subzero"
    },
    {
        name:"Cavas",
        text:`
            Las cavas de vino Sub-Zero son más que solo refrigeradores; son
            guardianes contra el calor, la humedad, vibración y luz. Los cuatro
            enemigos que pueden robarle al vino su complejidad, carácter y
            sabor.
        `,
        image: "img/brand-service/subzero-cavas.jpg",
        productImages: [],
        brand:"subzero"
    },
    {
        name:"Hornos Primarios",
        text:`
            Horno transicional, profesional, contemporaneo negro y
            contemporaneo en acero inoxidable de la serie M.
            Horno profesional de 24", doble transicional de
            30", profesional de 30" de la serie E y transicional de la serie M.
        `,
        image: "img/brand-service/wolf-hornos-primarios.jpg",
        productImages: [],
        brand:"wolf"
    },
    {
        name:"Hornos Secundarios",
        text:`
            Hornos de convección y vapor, speed oven, microondas y
            cajón caliente. Con complementos como la cafetera y sellador al vacío Wolf
            mejore todo aspecto de su cocina.
        `,
        image: "img/brand-service/wolf-hornos-secundarios.jpg",
        productImages: [],
        brand:"wolf",
    },
    {
        name:"Estufas y Parrillas Profesionales",
        text:`
            Dele a su cocina una pieza central icónica. Las estufas de gas y
            duales de Wolf han sido la elección de los profesionales durante
            ocho décadas, y que ahora son los favoritos de los cocineros del
            hogar exigentes. Los quemadores duales proporcionan un
            control preciso en flama alta y baja, mientras que el afamado
            horno de convección dual de Wolf proporciona resultados de
            cocción deliciosos y uniformes.
            Estufas duales, de gas, de inducción y parrillas
        `,
        image: "img/brand-service/wolf-estufas-parrillas-profesionales.jpg",
        productImages: [],
        brand:"wolf"
    },
    {
        name:"Parrillas Encimeras",
        text:`
            Personalice su cocina con módulos de placa de cocción
            que se adapten a su estilo de cocina único. Transforme
            sus encimeras con una estufa Wolf diseñada para
            adaptarse a usted; seleccione entre cualquier
            combinación de módulos para crear el espacio de trabajo
            de sus sueños con diferentes configuraciones y distintos
            módulos puede elige entre módulos eléctricos, de gas y
            de inducción.
        `,
        image: "img/brand-service/wolf-parrillas-encimeras.jpg",
        productImages: [],
        brand:"wolf"
    },
    {
        name:"Cocinas Exteriores",
        text:`
            Nuestras cocinas exteriores son fabricadas con acero
            inoxidable resistente a todos los climas. Con opciones de
            parrillas integradas o portátiles, parrillas modulares y
            muchas más opciones le damos opciones para extender
            su cocina hasta su patio.
        `,
        image: "img/brand-service/wolf-cocinas-exteriores.jpg",
        productImages: [],
        brand:"wolf"
    },
    {
        name:"Ventilación",
        text:`
            Elija el estilo profesional como un toque de acabado
            icónico u opciones más contemporáneas que pueden
            quedar ocultas fuera de la vista o instaladas como
            complemento a cualquier diseño de cocina moderna.
            Poderosos controles de extractor e iluminación halógena
            que mantienen el área de su cocina brillante, fresca y
            limpia, con filtros aptos para lavado en lavavajillas para
            una limpieza fácil.
        `,
        image: "img/brand-service/wolf-ventilacion.jpg",
        productImages: [],
        brand:"wolf"
    },
    {
        name:"Limpieza",
        text:`
            Los lavavajillas Cove ofrecen la más alta gama en lavavajillas, con interiores completamente flexibles, programas de lavado inteligentes y un funcionamiento silencioso. Además, cuenta con opciones panelables para ajustar su lavavajillas empotrable al diseño de su cocina.
            Con una amplia gama de opciones de ciclos de lavado y secado personalizados y un interior infinitamente ajustable,
            Cove garantiza que cada plato y utensilio que salga del lavavajillas estará impecablemente limpio y seco.
        `,
        image: "img/brand-service/cove-limpieza.jpg",
        productImages: [],
        brand:"cove"
    },
    {
        name:"Refrigeración",
        text:`
            ASKO diseña todos sus electrodomésticos con un compromiso a
            complementar la casa moderna. El estilo Escandinavo empotrado en
            nuestros refrigeradores puede mejorar la apariencia de cualquier
            cocina. Ademas, si usted prefiere también puede reemplazar las
            puertas con las suyas para complementar perfectamente sus
            gabinetes existentes.
        `,
        image: "img/brand-service/asko-refrigeracion.jpg",
        productImages: [],
        brand:"asko"
    },
    {
        name:"Otros Equipos",
        text:`
            Con una filosofía única de interacción y control basada en una
            interfaz FT touch, la cafetera ASKO tine todas las posibilidades para
            preparar su café de manera perfecta, justo como usted lo
            desea. Además, la campana ASKO se ha creado para facilitar una
            cocción limpia y sin humo en cocinas de todos los tamaños.
        `,
        image: "img/brand-service/asko-otros-equipos.jpg",
        productImages: [],
        brand:"asko"
    },
    {
        name:"Hornos",
        text:`
            La combinación del funcionalismo cotidiano, la preocupación por el
            medio ambiente y las líneas limpias y agradables es el sello principal
            del diseño escandinavo, y el de ASKO. Para distinquirnos en un
            mercado de diseños abarrotados, complejos y voluptuosos,
            buscamos un minimalismo suave y humanista basado en el
            principio de silencio como el nuevo ruido. Con nuestra colección de
            hornos primarios y secundarios puede ver un ejemplo de nuestro
            diseño y funcionalismo.
        `,
        image: "img/brand-service/asko-hornos.jpg",
        productImages: [],
        brand:"asko"
    },
    {
        name:"Parrillas",
        text:`
            Todas las parrillas ASK vienen equipadas con quemadores únicos
            que produce una flama pura y perfectamente ajustable que no solo
            produce un alto rendimiento, per también direcciona la flama
            precisamente debajo de su sartén. Los puertos de flama están
            ingeniados precisamente para asegurar que la flama tenga la
            distancia más corta a debajo del sartén. Esto hace que el calor se
            distribuva proporcionalmente a toda la superficie del sarten
        `,
        image: "img/brand-service/asko-parrillas.jpg",
        productImages: [],
        brand:"asko"
    },
    {
        name:"Lavavajillas",
        text:`
            Las lavavajillas ASKO están hechas de acero. Este ha sido un hecho
            bien conocido durante décadas y cuando abra la puerta, verá más
            acero que en cualquier otro lavavajillas del mercado. Con nuestra
            nueva generación de lavavajillas queremos enfatizar este hecho aún
            más. Eche un vistazo a la puerta y verá solo acero de alta calidad sin
            división entre la puerta y el panel. Se expresa a si misma como un
            electrodoméstico confiable y robusta, que conserva líneas elegantes
            y sofisticadas derivadas del minimalismo y la mentalidad
            escandinava
        `,
        image: "img/brand-service/asko-lavavajillas.jpg",
        productImages: [],
        brand:"asko"
    },
    {
        name:"Lavadora y Secadora",
        text:`
            Más inteligente por diseño. Las lavadoras de ASK están repletas de
            caracteristicas innovadoras diseñadas para facilitar la vida diaria. Y
            debido a que están hechas con piezas de acero de calidad, siempre
            puede esperar años de servicio confiable. Si bien cada
            electrodoméstico sobresale por sí solo, los equips de limpieza de
            ASKO también están diseñados para complementarse entre si. Esto
            le permite combinar sin esfuerzo su lavadora con una secadora, un
            armario de secado y otros products de lavandería ASKO
        `,
        image: "img/brand-service/asko-lavadora-secadora.jpg",
        productImages: [],
        brand:"asko"
    },
    {
        name:"Maquina de Hielo",
        text:`
            Las máquinas de hielo Scotsman producen hielo que no cambia ni reduce los sabores, contrario a lo que hace el hielo de un refrigerador regular. Cuentan con un sensor de calidad de agua que detecta las condiciones de agua dura y extrae los restos de minerales.
            La tecnología en las máquinas de hielo Brilliance permiten un ahorro de energía del 40-50% y ahorro de agua del 30-50%, mejorando así nuestros modelos previos
        `,
        image: "img/brand-service/scotsman-maquina-hielo.jpg",
        productImages: [],
        brand:"Scotsman"
    },
    {
        name:"Hielo tipo Nugget (Masticable)",
        text:`
            Nuestro hielo nugget ice, suave, cilíndrico y de derretido lento, masticable. Las bebidas especiales exigen una forma de hielo muy especial. Desde batidos de frutas y cappuccinos fríos hasta margaritas y más, Nugget Ice® de Scotsman ayuda a enfriar las bebidas rápidamente sin generar espuma y refrescando el paladar.
        `,
        image: "img/brand-service/scotsman-nigget.jpg",
        productImages: [],
        brand:"Scotsman"
    },
    {
        name:"Hielo Gourmet",
        text:`
            Nuestros cubos de hielo gourmet especiales, son ideales para usar tanto en su hogar como en barras, oficinas y salas de descanso. Su tamaño y duración los convierte en la elección perfecta para volver a enfriar vasos, mientras que su antiadherencia y derretido lento facilitan aún más su servido. Las dimensiones exactas del cubo gourmet son 1” x 11∕8” x 11⁄4”.
        `,
        image: "img/brand-service/scotsman-gourmet.jpg",
        productImages: [],
        brand:"Scotsman"
    },
    {
        name:"Detalles que marcan la diferencia",
        text:`
            •Diseño – Las líneas de diseño versátiles y sofisticadas de los fregaderos Franke se adaptan perfectamente a todos los contextos, tanto modernos como clásicos.<br/>
            •Colores naturales - Fragranite ofrece una amplia selección de colores, desde tonos cálidos para los acabados más clásicos, hasta blanco y negro para combinaciones contemporáneas. <br/> •Accesorios - Los fregaderos de cocina Franke tienen numerosos accesorios que se integran con los diversos modelos.<br/>
            •Fast Fix - Sistema de fácil instalación.<br/>
            •Cubierta premium – Desagüe imperceptible para dar un efecto estético y moderno.
        `,
        image: "img/brand-service/franke-detalles.jpg",
        productImages: [],
        brand:"franke"
    },
    {
        name:"Acero inoxidable de alto grado",
        text:`
            Las tarjas están hechas de acero inoxidable de grado 304 con los porcentajes más altos en la industria, de cromo (18%) y níquel (10%); este alto contenido de cromo y níquel hace que el acero sea más resistente a la corrosión.
        `,
        image: "img/brand-service/franke-alto-grado.jpg",
        productImages: [],
        brand:"franke"
    },
    {
        name:"Acero inoxidable ecológico",
        text:`
            Las tarjas están fabricadas con un 70% de acero inoxidable reciclado no contaminado. Franke invierte en acero inoxidable de alto costo. Además cuenta con equipos de produccion de ultima tecnología, para así garantizar la pureza del acero reciclado.
        `,
        image: "img/brand-service/franke-ecologico.jpg",
        productImages: [],
        brand:"franke"
    },
    {
        name:"Plum wine",
        text:`
        El sistema de conservacion de vino de Plum refleja nuestra mision de ofrecer la
        copa de vino perfectamente a pedido, en todo momento, conservada y a la temperatura ideal
        para servir. <br/>
        Plum es el primer equipo de vino, completamente automatico que identifica, enfria, conserva
        y sirve el vino automaticamente, por lo que se puede disfrutar de una copa a la vez <br/>
        Con su exterior de acero inoxidable cepillado de hermoso diseño de Plum la convierte en una elegante
        adición a su hogar
        `,
        image: "img/brand-service/plum-wine.jpg",
        productImages: [],
        brand:"plum"
    },
    {
        name:"Licuadora de alto rendimiento",
        text:`
            Con la capacidad de hacer de todo, desde batidos para
            el desayuno hasta cenas de varios platos, la licuadora de
            rendimienta profesional de Wolf Gourmet ofrece una
            velocidad, potencia y versatilidad excepcionales El
            motor de 2,2 caballos de fuerza máxima impulsa las
            cuchillas a velocidades de más de 200 millas por hora,
            brindando resultados superiors para todo tipo de
            alimentos
        `,
        image: "img/brand-service/wolf-gourmetRecurso16-80.jpg",
        productImages: [],
        brand:"wolf-gourmet"
    },
    {
        name:"Horno de sobremesa Elite con convección",
        text:`
            El horno de encimera más avanzado de Wolf Gourmet
            ofrece la precisión, la calidad y el aspecto de un horno
            Wolf en forma de encimera. Las características clásicas
            de Wolf, como la convección y una sonda de
            temperatura integrada, combinadas con un grupo de
            actualizaciones de alto rendimiento preparan el
            scenario para una experiencia de cocina agradable y
            segura.
        `,
        image: "img/brand-service/wolf-gourmetRecurso1-80.jpg",
        productImages: [],
        brand:"wolf-gourmet"
    },
    {
        name:"Plancha de precisión",
        text:`
            Una placa de cocción, infinitas posibilidades. La plancha
            de precisión Wolf Gourmet ofrece una gran superficie de
            cocción, control de temperatura avanzado y
            características de inspiración más profesional que le
            permiten dorar, saltear, asar, derretir y cocinar al vapor
            sus comidas favoritas
        `,
        image: "img/brand-service/wolf-gourmetRecurso-3-80.jpg",
        productImages: [],
        brand:"wolf-gourmet"
    },
    {
        name:"Sistema de Café Programable",
        text:`
            Despiértese con una taza de café superior con el sistema
            de café programable Wolf Gourmet. La exclusiva
            configuración Accu-Brew presenta una báscula integrada
            y un panel de visualización LCD que le permite saber
            exactamente cuánto café agregar, para que disfrute de
            una taza consistente tadas las mañanas. Cinco opsiones
            de audacia le permiten personalizar su lot exactamente
            como le gusta, y una jarra térmica de 10 tazas mantiene
            el café caliente y fresco mucho después de que se haya
            preparado
        `,
        image: "img/brand-service/wolf-gourmetRecurso15-80.jpg",
        productImages: [],
        brand:"wolf-gourmet"
    },
    {
        name:"Multi-Function Cooker",
        text:`
            Cocine a fuego lento un asado, dore un bistec, sous vide
            un filete de pescado y más usando el mismo aparato
            versátil a la olla multifunción Wolf Gourmet cuenta con
            cinco modos diferentes dedicados a la cocción lenta,
            junto con funciones especializadas para saltear/dorar,
            sous vide y arroz.
        `,
        image: "img/brand-service/wolf-gourmetRecurso-17-80.jpg",
        productImages: [],
        brand:"wolf-gourmet"
    },
    {
        name:"Tostadora de dos Tostadora de cuatro rebanadas",
        text:`
            Las tostadoras Wolf Gourmet cuentan con tecnologia de
            tostado avanzada. Diseñados con guías de pan
            autocentrantes únicas, brindan resultados perfectamente
            uniformes, independientemente del tipo de pan. Disfrute
            de panecillos ingleses crujientes, brioche dorado y bagels
            cruijientes pero masticables. Usted elige su cantidad ideal
            de dorado con el control preciso de la perilla selectora
            de tono
        `,
        image: "img/brand-service/wolf-gourmetRecurso14-80.jpg",
        productImages: [],
        brand:"wolf-gourmet"
    },
]