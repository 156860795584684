<template>
  <div>
    <div class="marcas-main-bg cove-main-bg">
      <div class="w-full h-full block">
        <div class="container h-full">
          <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h2 class="main-text">Cove</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-12">
      <div class="container text-center">
        <h2>
    No hay mejor sensación que el comienzo chispeante de una comida.
    Cuando la mesa está bellamente puesta, los cubiertos relucen, y el vino está listo para llenar
    cada copa reluciente. Con el lavavajillas Cove, cada ocasión puede comenzar
    brillantemente con platos siempre limpios y secos.
        </h2>
      </div>
    </div>
    <brandsSeries brand="cove" />

    <div>
<!--       <div class="grid grids-cols-1 md:grid-cols-3 gap-2">
        <div
          class="flex flex-col text-center justify-items-center items-center"
        >
          <h2>LIMPIEZA EN EL DISENO</h2>
          <img src="img/subcategories/cove-limpieza.png" alt="" />
        </div>
        <div
          class="flex flex-col text-center justify-items-center items-center"
        >
          <h2>IMPECABLE Y BRILLANTE</h2>
          <img src="img/subcategories/cove-impecable.png" alt="" />
        </div>
        <div
          class="flex flex-col text-center justify-items-center items-center"
        >
          <h2>HECHA PARA PERSONALIZARSE</h2>
          <img src="img/subcategories/cove-personalizable.png" alt="" />
        </div>
      </div>
      <div>
        <b-collapse :open="collapseOpen">
          <div v-if="!!collapseOpen" class="notification">
            <div class="content">
              <div class="grid grids-cols-1 lg:grid-cols-2 justify-items-center mb-6">
                <div class="text-center lg:text-right lg:w-2/5 w-full">
                  <h3>{{subcategories[subcategory].title}}</h3>
                </div>
                <p class="text-center lg:text-left lg:w-3/5 w-full">
                    {{subcategories[subcategory].description}}
                </p>
              </div>
              <div>
                <div class="grid grid-cols-1 lg:grid-cols-3 justify-items-center">
                  <div
                    v-for="(feat, i) in subcategories[subcategory].features" 
                    :key="i"
                    class="
                      flex flex-col
                      text-center
                      justify-items-center
                      items-center
                      mt-6
                      lg:mt-0
                    "
                  >
                    <h4>{{feat.type}}</h4>
                    <p class="px-4">
                        {{feat.description}}
                    </p>
                    <img
                      :src="feat.image"
                      :alt="feat.type"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div> -->
    </div>
  </div>
</template>
<script>
import brandsSeries from './../BrandsSeries.vue'
export default {
  components:{
    brandsSeries
  },
  data() {
    return {
      collapseOpen: false,
      subcategory: null,
      subcategories: {
        refrigeradores: {
          title: "Refrigeradores Altos",
          description: `Sub-Zero es más que un refrigerador, es un sistema de conservación de alimentos
            de tamaño completo. Es un monumento a la comida fresca y deliciosa.
            Con una amplia selección de diseños, anchos y estilos de tamaño completo y personalizables,
            las unidades Sub-Zero están diseñadas para satisfacer las necesidades
            de cualquier espacio y mejorar cualquier decoración.`,
          features: [
            {
              type: "ACABADOS",
              description: `Seleccione entre un aspecto limpio
                        se acero inoxidable o paneles 
                        personalizados para coordinar con los
                        gabinetes de la cocina.`,
              image: "img/subcategories/subzero-refrigeración-acabados.png",
            },
            {
              type: "CONFIGURACIONES",
              description: `Las unidades de tamaño completo
                        incluyen estilos lado a lado, arriba y
                        abajo, todo refrigerador, todo congelador
                        y puertas francesas.`,
              image:
                "img/subcategories/subzero-refrigeración-configuraciones.png",
            },
            {
              type: "ANCHOS",
              description: `Maximice cualquier espacio con las
                        unidades más delgadas de 18 pulgadas 
                        a combinaciones que abarcan
                        hasta 72 pulgadas de ancho.`,
              image: "img/subcategories/subzero-refrigeración-anchos.png",
            }
          ]
        },
        cavas: {
          title: "Cavas",
          description: 
            `Las unidades de almacenamiento de vino sub-zero son más que solo refrigeradores;
            son guardianes contra el calor, la humedad, la vibración y la luz,
            los cuatro enemigos que pueden despojar al vino de su complejidad, carácter y sabor.`,
          features: [
             {
                type:'TIPOS',
                description:`
                  Seleccione libre instalación. O empotrado. 
                  Columna o bajo cubierta,arriba y abajo junto 
                  con cajones de refrigerador y/o congelador.`,
                  image:'img/subcategories/subzero-cavas-tipos.png'
            },
            {
              type: "TAMAÑO Y CAPACIDAD",
              description: `Disfrute de la flexibilidad en los
                anchos de las cavas (18″, 24″, 30″)
                así como la capacidad de almacenar
                botellas (desde 46 hasta 147).`,
              image: "img/subcategories/subzero-cavas-tamaño-y-capacidad.png",
            },
            {
              type: "ACABADOS",
              description: 
                `De acero inoxidable o panalable: 
                seleccione el aspecto clásico y limpio
                de acero inoxidable o paneles personalizados 
                para coordinar con sus gabinetes.`,
              image:
                "img/subcategories/subzero-cavas-acabados.png",
            }
          ]
        },
        cubiertas: {
          title: "Bajo Cubiertas",
          description: 
            `Productos frescos en la isla de la cocina, bebidas frías en el gimnasio, hielo en el área de la piscina.
            La refrigeración sub-zero es refrigeración en cualquier ligar.
            Ya sea que elija cajones integrados, centros de bebidas, refrigeradores bajo cubierta
            o máquinas de hielo, nuestras unidades se adaptan perfectamente
            al flujo de su hogar y la forma en que vive.`,
          features: [
             {
                type:'OPCIONES',
                description:
                  `Abundan las opciones de bajo cubierta, 
                  desde cavas, cajones refriferadores hasta
                  máquinas de hielo, centros de bebidas y más.`,
                image: "img/subcategories/Subzero-bajo-cubierta-opciones.png",
            },
            {
              type: "REFRIGERADORES DONDE SEA",
              description: 
                `Los estilos de vida han cambiado.
                También la refrigeración. El secreto
                es colocar los electrodomésticos
                donde las personas los usan, tanto
                dentro como fuera de casa.`,
              image: "img/subcategories/subzero-bajo-cubierta-refrigeración.png",
            },
            {
              type: "ACABADOS",
              description: 
                `De acero inoxidable o panalable: 
                seleccione el aspecto clásico y limpio
                de acero inoxidable o paneles personalizados 
                para coordinar con sus gabinetes.`,
              image:
                "img/subcategories/subzero-bajo-cubierta-acabados.png",
            },
          ],
        },
      },
    };
  },
  methods: {
    handlerCollapse: function (subcategory) {
      if(subcategory == this.subcategory){
        this.subcategory = null;
        this.collapseOpen = false;
      }else{
        this.subcategory = subcategory;
        this.collapseOpen = true;
      }
      
    },
  },
  mounted(){
    window.scrollTo(0, 0)
  }
};
</script>