<template>
  <div>
    <div class="marcas-main-bg plum-main-bg">
      <div class="w-full h-full block">
        <div class="container h-full">
          <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h2 class="main-text">Plum</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-12">
      <div class="container text-center">
        <h2>
            Plum es el primer electrodoméstico totalmente automático que
            identifica, enfría, conserva y sirve automáticamente su vino
            para que pueda disfrutar de una copa a la vez
        </h2>
      </div>
    </div>


    <div class="flex justify-center">
      <video controls>
        <source src="img/plum-wine-video.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    <brandsSeries brand="plum" />
    
  </div>
</template>
<script>
import brandsSeries from './../BrandsSeries.vue'
export default {
  components:{
    brandsSeries
  },
  methods: {
    handlerCollapse: function (subcategory) {
      if(subcategory == this.subcategory){
        this.subcategory = null;
        this.collapseOpen = false;
      }else{
        this.subcategory = subcategory;
        this.collapseOpen = true;
      }
      
    },
  },
  mounted(){
    window.scrollTo(0, 0)
  }
};
</script>