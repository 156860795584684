import Welcome from './Welcome.vue'
import HelloWorld from './HelloWorld.vue'
import Catalog from './Catalog.vue'
import SubZero from './marcas/sub-zero.vue'
import Wolf from './marcas/wolf.vue'
import WolfGourmet from './marcas/WolfGourmet.vue'
import Cove from './marcas/cove.vue'
import Asko from './marcas/asko.vue'
import Franke from './marcas/franke.vue'
import Scotsman from './marcas/scotsman.vue'
import Plum from './marcas/plum.vue'
import Contacto from './Contacto.vue'
import Product from './Product.vue'

export const routes = [
    { path: '/hello-world', component: HelloWorld },
    { path: '/catalogo', component: Catalog },
    { path: '/sub-zero', component: SubZero },
    { path: '/wolf', component: Wolf },
    { path: '/wolf-gourmet', component: WolfGourmet },
    { path: '/cove', component: Cove },
    { path: '/asko', component: Asko },
    { path: '/franke', component: Franke },
    { path: '/scotsman', component: Scotsman },
    { path: '/plum', component: Plum },
    { path: '/contacto', component: Contacto },
    { path: '/', component: Welcome },
    { path: '/product/{productId}', component:Product}
]